import React from "react";
import "./style.scss";

export const LinkMessenger = ({ link, text, img, className, ariaLabel }) => {
  return (
    <a
      className={`${className} link-messenger`}
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      aria-label={ariaLabel}
    >
      <img src={img} alt="" />
      <span>{text}</span>
    </a>
  );
};
